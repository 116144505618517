import { useMutation } from "@apollo/client";
import { CREATE_SCHEDULE_PLAYLIST_MUTATION } from "graphql/mutations";

export function useCreateSchedule() {
  const [createSchedulePlaylist, { loading }] = useMutation(
    CREATE_SCHEDULE_PLAYLIST_MUTATION
  );

  return [
    ({ variables, schedule }) => {
      const { date, classIds } = variables;
      const { startDate, endDate } = schedule;

      return createSchedulePlaylist({
        variables: {
          date,
          classIds: classIds.map(id => ({ id })),
        },
        update: (cache, { data }) => {
          cache.modify({
            fields: {
              getCalendarData: (
                existingData,
                { toReference, storeFieldName }
              ) => {
                if (
                  storeFieldName ===
                  `getCalendarData({"input":{"endDate":"${endDate}","startDate":"${startDate}"}})`
                ) {
                  const newCalendarData = Object.assign({}, existingData);
                  const newCalendarRef = toReference({
                    id: data?.createSchedulePlaylist?.id,
                    __typename: "Playlist",
                  });
                  newCalendarData.userCreatedPlaylists = [
                    newCalendarRef,
                    ...existingData.userCreatedPlaylists,
                  ];
                  return newCalendarData;
                }
                return existingData;
              },
              getPlaylist: (existingData, { toReference, storeFieldName }) => {
                if (storeFieldName === `getPlaylist({"date":"${date}"})`) {
                  return toReference({
                    id: data?.createSchedulePlaylist?.id,
                    __typename: "Playlist",
                  });
                }
                return existingData;
              },
            },
          });
        },
      });
    },
    loading,
  ];
}
